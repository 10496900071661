import { FormControl, Container } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FilterCar } from "../../components/modals/filterCar/component";
import { NavFilter } from "../../components/navFilter/component";
import { useSort } from "../../hooks/sortCars";
import { LeftFilter } from "../../lib/components/resultsTools/leftFilter/component";
import { ShowResult } from "../../lib/components/resultsTools/showResult/component";
import {
  findByLatitudLongitud,
  getAllAddressComponents,
} from "../../lib/helpers/mapsActions";
import { handlePages } from "../../lib/helpers/pageHandler";
import { TranslationContext } from "../driverReservation/show/component";
import "./Results.scss";
import {
  FilterContext,
  Filters,
  MAXPrice,
  MINDistance,
  MINPrice,
  mockFilters,
  mockResultParams,
  resultParams,
} from "./types";
import useResults from "../../hooks/results/useResults";
import Toast from "../../components/toast/component";
import { SortDropdown } from "../../lib/components/resultsTools/sortDropdown/component";
import { SortContext } from "../../lib/components/resultsTools/sortDropdown/types";
import dayjs from "dayjs";
import Loading from "../../components/loading/component";
import { EmptyState } from "../../lib/components/resultsTools/emptyState/component";
import { Helmet } from "react-helmet";

export const Results: FC = () => {
  const { t } = useTranslation(["results"]);
  const [params, setParams] = useState<resultParams>(mockResultParams);
  const [sortBy, setSortBy] = useState("RECENT");
  const [page, setPage] = useState(1);
  const {
    cars,
    getResultCars,
    errorMsg,
    openAlert,
    setOpenAlert,
    carsLoading,
  } = useResults(params, setPage);
  const sorted = useSort(cars ?? [], sortBy);
  const [search] = useSearchParams();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [filters, setFilters] = useState<Filters>(mockFilters);

  useEffect(() => {
    const latitud = search.get("latitud") ?? "0";
    const longitud = search.get("longitud") ?? "0";
    const destinationId = search.get("destinationId") ?? "0";
    const dateInitial = search.get("dateInitial") ?? "";
    const hourInitial = search.get("hourInitial") ?? "";
    const commercialName = search.get("commercialName") ?? "";
    const dateEnd = search.get("dateEnd") ?? "";
    const hourEnd = search.get("hourEnd") ?? "";
    const experiences = search.get("experiences") ?? "000000000";
    const transmission = search.get("transmission") ?? "01";
    const type = search.get("type") ?? "0123456";
    const numBags = search.get("numBags");
    const numSeats = search.get("numSeats");
    const address = search.get("address") ?? "0";
    const ecologyCar = search.get("ecologyCar") ?? "0";
    const depositAmount = search.get("depositAmount") ?? "0";
    // const price = search.get("price") || "100,10000";
    const priceLowerBound = search.get("priceLowerBound") ?? MINPrice;
    const priceUpperBound = search.get("priceUpperBound") ?? MAXPrice;
    const distance = search.get("distance") ?? MINDistance;

    let urlParams: resultParams | undefined;
    const setAddressFilter = async () => {
      const addressFilterData = await findByLatitudLongitud(latitud, longitud);
      let addressItem;
      if (addressFilterData) {
        addressItem = getAllAddressComponents(
          addressFilterData.address_components
        );
      }
      const stateParam = addressItem
        ? addressItem?.administrative_area_level_1
        : "";

      urlParams = {
        state: stateParam,
        latitud,
        commercialName,
        longitud,
        dateInitial,
        dateEnd,
        hourInitial,
        hourEnd,
        experiences,
        type,
        transmission,
        destinationId: parseInt(destinationId),
        numSeats: numSeats ? parseInt(numSeats) : null,
        numBags: numBags ? parseInt(numBags) : null,
        depositAmount,
        ecologyCar,
        address,
        priceLowerBound: parseFloat(priceLowerBound),
        priceUpperBound: parseFloat(priceUpperBound),
        distance: parseFloat(distance),
      };
      setParams(urlParams as resultParams);

      setFilters({
        transmission: {
          "0": transmission.includes("0"),
          "1": transmission.includes("1"),
        },
        type: {
          "0": type.includes("0"),
          "1": type.includes("1"),
          "2": type.includes("2"),
          "3": type.includes("3"),
          "4": type.includes("4"),
          "5": type.includes("5"),
          "6": type.includes("6"),
        },
        experiences: {
          "0": experiences[0] === "1",
          "1": experiences[1] === "1",
          "2": experiences[2] === "1",
          "3": experiences[3] === "1",
          "4": experiences[4] === "1",
          "5": experiences[5] === "1",
          "6": experiences[6] === "1",
          "7": experiences[7] === "1",
          "8": experiences[8] === "1",
        },
        address: address === "1",
        depositAmount: depositAmount === "1",
        ecologyCar: ecologyCar === "1",
        price: [parseFloat(priceLowerBound), parseFloat(priceUpperBound)],
        distance: parseFloat(distance),
        numSeats: numSeats ? parseInt(numSeats) : undefined,
        numBags: numBags ? parseInt(numBags) : undefined,
      });
    };
    setAddressFilter().then(() => {
      const formatedDateInitial = dayjs(dateInitial, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      const formatedDateEnd = dayjs(dateEnd, "DD/MM/YYYY").format("YYYY-MM-DD");
      !!urlParams &&
        getResultCars({
          ...urlParams,
          dateEnd: formatedDateEnd,
          dateInitial: formatedDateInitial,
        });
    });
  }, [search, setParams, setFilters, getResultCars]);

  const pageCounter = `${page === 1 ? page : 8 * (page - 1) + 1}-${
    sorted.data.length < 8 * page ? sorted.data.length : 8 * page
  } de ${sorted.data.length}`;

  const dataSorted =
    sorted.data.length === 0 && !carsLoading ? (
      <EmptyState />
    ) : carsLoading ? (
      <Loading height="25vh" />
    ) : (
      <ShowResult page={page} cars={sorted.data} params={params} />
    );

  return (
    <FilterContext.Provider value={[filters, setFilters]}>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
        <meta property="og:title" content={t("seo.og_title")} />
        <meta property="og:description" content={t("seo.og_description")} />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href="https://romio.mx/resultados" />
        <meta property="og:url" content="https://romio.mx/resultados" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={t("seo.og_locale")} />
        <link
          rel="alternate"
          hrefLang="es"
          href="https://romio.mx/resultados"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/resultados"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/resultados"
        />
      </Helmet>
      <Toast open={openAlert} setOpen={setOpenAlert} type="error">
        {errorMsg}
      </Toast>

      <TranslationContext.Provider value={t}>
        <NavFilter {...params} />
        <div className="results-container">
          <LeftFilter params={params} setParams={setParams} setPage={setPage} />
          <Container className="car-container-index">
            <SortContext.Provider value={{ setSortBy }}>
              <FilterCar
                params={params}
                setPage={setPage}
                setParams={setParams}
                counter={pageCounter}
              />
            </SortContext.Provider>
            <div className="filter-car-desktop">
              <FormControl className="sort-by-container d-flex">
                <p>
                  {t("counter")}
                  {pageCounter}
                </p>
                <SortContext.Provider value={{ setSortBy }}>
                  <SortDropdown />
                </SortContext.Provider>
              </FormControl>
            </div>

            <article id="car1" className="car-index">
              {dataSorted}
            </article>
            {sorted.data.length !== 0 && (
              <>
                <Stack className="car-pagination">
                  <Pagination
                    count={handlePages(sorted.data)}
                    variant="outlined"
                    color="primary"
                    size="large"
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </>
            )}
          </Container>
        </div>
      </TranslationContext.Provider>
    </FilterContext.Provider>
  );
};
