import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getUserByID } from "../../services/user";
import {
  getFileDetailsIfExist,
  uploadioEnv,
} from "../../services/fileUploader";
import { getHostByUserId, getHostExistByUserId } from "../../services/host";
import { Skeleton, Tooltip } from "@mui/material";
import { HostStatus } from "../../models/host/types";
import authenticationImages from "../../assets/images/authentication";
import reservationImages from "../../assets/images/reservations";
import { capitalize } from "../../lib/helpers/textHandler";
import "./MinHostCard.scss";

export const MinHostCard: FC<{ className?: string; userId: number }> = ({
  className,
  userId,
}) => {
  const { t } = useTranslation(["car"]);

  const { data: userData } = useQuery(
    ["user", userId],
    () => getUserByID(userId),
    {
      enabled: !!userId,
    }
  );

  const { data: hostImage } = useQuery(
    ["hostImage", userId],
    () =>
      getFileDetailsIfExist(
        `/${uploadioEnv}/user-${userId}/foto_perfil`,
        `/${uploadioEnv}/user-${userId}`
      ),
    {
      enabled: !!userId,
    }
  );

  const { data: isHostCommercial, isLoading } = useQuery(
    ["isHostCommercial", userId],
    () => getHostExistByUserId(userId.toString() || ""),
    {
      enabled: !!userId,
    }
  );

  const existHost = isHostCommercial || false;
  const { data: hostC } = useQuery(
    ["hostC", userId],
    () => getHostByUserId(userId.toString() || ""),
    {
      enabled: existHost,
    }
  );

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        className={`trip-user-card ${className}`}
        height={70}
      />
    );
  }

  if (isHostCommercial && hostC && hostC.status === HostStatus.VALIDATED) {
    return (
      <div
        className={`trip-user-card ${className !== undefined ? className : ""}`}
      >
        <img
          src={
            hostImage
              ? hostImage.fileUrl.replace("raw", "profile-picture")
              : authenticationImages.profileDefault
          }
          className="user-img"
          alt=""
        />
        <div className="user-info">
          <h3 className="name" id="nombre" style={{ margin: "0" }}>
            {capitalize(hostC.businessName)}
          </h3>
          <span className="hostC-indicator">
            {t("show.car_form.host_commercial")}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={`trip-user-card ${className}`}>
      <img
        src={
          hostImage
            ? hostImage.fileUrl.replace("raw", "profile-picture")
            : authenticationImages.profileDefault
        }
        className="user-img"
        alt=""
      />
      <div className="user-info">
        <h3 className="name" id="nombre">
          {capitalize(userData?.name || "")}
        </h3>
        <div className="host-indicator-container">
          <Tooltip title={t("show.car_form.user_tooltip")} arrow>
            <>
              <span className="hostC-indicator">
                {t("show.car_form.verified_host")}{" "}
              </span>
              <img
                src={reservationImages.checkVerified}
                className="check-host"
                alt=""
              />
            </>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
