export interface Car {
  id: number;
  userId: number; //appUser_Id
  brand: string;
  model: string;
  version: string;
  year: string;
  amis: string; // amic
  plate: string;
  niv?: string;
  transmission: Transmission;
  type: CarType;
  numSeats?: number;
  docVehicleLicense: ValidateInfo;
  price: string;
  questionnaireAnswers?: string;
  characteristicsList?: string;
  status: CarStatus;
  description?: string;
  rules: string;
  commission: string;
  isReadyByHost: Deactivated;
  docInsuranceCover: DocInsuranceCover;
  vehicleLicenseExpirationDate: string;
  statusVehicleLicense: StatusVehicleLicense;
  insuranceCoverExpirationDate: string;
  statusInsuranceCover: StatusInsuranceCover;
  isEcoFriendly: IsEcofriendly;
  experiences: string;
  numBags: number;
  offerGPS: OfferGPS;
  hasGPS: HasGPS;
  confirmedGPS: ConfirmedGPS;
  destinationId: number;
  step?: number;
  isCarComplete?: boolean;
}

export interface CarResults extends Car {
  datetimeCreated: string;
  datetimeCompleted: string;
  datetimeActive: string;
  latitude: string;
  longitude: string;
}

export interface results {
  foundByDestinationId: boolean;
  carList: CarResults[];
}

export enum CarStatus {
  INCOMPLETE,
  PENDING,
  ACTIVE,
  BLOCKED,
}

export enum Deactivated {
  READY,
  DISABLED,
  REMOVED,
}

export enum Transmission {
  AUTOMATIC,
  MANUAL,
}

export enum CarType {
  COMPACT,
  SEDAN,
  COUPE,
  SUV,
  MINIVAN,
  VAN,
  TRUCK,
}

export enum ValidateInfo {
  NOT_UPLOADED,
  PENDING,
  VALIDATED,
  REFUSED,
}

export enum StatusVehicleLicense {
  VALID,
  ABOUT_TO_EXPIRE,
  EXPIRED,
}

export enum StatusInsuranceCover {
  VALID,
  ABOUT_TO_EXPIRE,
  EXPIRED,
}

export enum DocInsuranceCover {
  NOT_UPLOADED,
  PENDING,
  VALIDATED,
  REFUSED,
}

export enum HasGPS {
  UNKNOWN,
  FALSE,
  TRUE,
}

export enum OfferGPS {
  NOT_INTERESTED,
  INTERESTED,
}

export enum ConfirmedGPS {
  FALSE,
  TRUE_RESSER,
  TRUE_JEST,
}

export enum IsEcofriendly {
  NEGATIVE,
  ELECTRIC,
  HYBRID,
}

export const Accesories = [
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
  "0",
];
