import { CSSProperties } from "react";
import {
  CarType,
  Deactivated,
  IsEcofriendly,
  Transmission,
} from "./../../../models/car/types";
import { CarStatus } from "../../../models/car/types";

export const getStatus = (
  carStatus: CarStatus,
  isReadyByHost: Deactivated
): string => {
  let status;

  switch (true) {
    case isReadyByHost == Deactivated.REMOVED:
      status = "ELIMINADO";
      break;
    case carStatus == CarStatus.ACTIVE:
    case carStatus == CarStatus.INCOMPLETE:
    case carStatus == CarStatus.BLOCKED:
    case carStatus == CarStatus.PENDING:
      if (isReadyByHost == Deactivated.DISABLED) {
        status = "DESACTIVADO";
      } else {
        status = listStatus[carStatus];
      }
      break;
    default:
      status = "N/A";
      break;
  }
  return status;
};

export const listStatus = [
  "INCOMPLETO",
  "PENDIENTE",
  "ACTIVO",
  "DESACTIVADO",
  "ELIMINADO",
  "BLOQUEADO",
];

export const styles = {
  INCOMPLETO: {
    background: "#219653",
  },

  PENDIENTE: {
    background: "#f2c94c",
  },

  ACTIVO: {
    background: "#3cb2b9",
  },

  DESACTIVADO: {
    background: "#eb5757",
  },

  ELIMINADO: {
    background: "#eb5757",
  },

  BLOQUEADO: {
    background: "#f76f8e",
  },
};

export const getType = (type: CarType) => {
  const listTypes = [
    "Compacto",
    "Sedan",
    "Coupe",
    "SUV",
    "Minivan",
    "Van",
    "Troca",
  ];
  return listTypes[type];
};

export const getTransmission = (Transmission: Transmission) => {
  const listTransmission = ["Automático", "Manual"];
  return listTransmission[Transmission];
};

export const getIsEcofriendly = (isEcofriendly: IsEcofriendly) => {
  const listEcofriendly = ["No", "Eléctrico", "Híbrido"];
  return listEcofriendly[isEcofriendly];
};

export const handleCarStatusColor = (
  carStatus: string
): CSSProperties | undefined => {
  switch (carStatus) {
    case "INCOMPLETO":
      return styles.INCOMPLETO;
    case "PENDIENTE":
      return styles.PENDIENTE;
    case "ACTIVO":
      return styles.ACTIVO;
    case "DESACTIVADO":
      return styles.DESACTIVADO;
    case "ELIMINADO":
      return styles.ELIMINADO;
    case "BLOQUEADO":
      return styles.BLOQUEADO;
  }
};
