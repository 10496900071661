import { CreateHostDTO, UpdateHostDTO } from "./../models/host/dtos";
import { axiosService } from "./axios";
import { Host } from "./../models/host/types";
import { getToken } from "./localstorage";

export const getHost = async () => {
  try {
    const response = await axiosService.get<Host>("/host/user", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getHostByUserId = async (userId?: string) => {
  try {
    const response = await axiosService.get<Host>(`/host/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getHostExistByUserId = async (userId: string) => {
  try {
    const response = await axiosService.get(`/host/alow/${userId}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createHost = async (HostDTO: CreateHostDTO) => {
  try {
    const data = await axiosService.post<Host>("/host/", HostDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateHost = async (id: number, HostDTO: UpdateHostDTO) => {
  try {
    const response = await axiosService.patch<Host>(`/host/${id}`, HostDTO, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteHost = async (id: string, index: number) => {
  try {
    const data = await axiosService.delete<Host>(`/host/${id}`);
    return { data, index };
  } catch (error) {
    console.error(error);
  }
};
