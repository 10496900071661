import { carSpecifications, UpdateCarDTO } from "./dtos";
import {
  Car,
  CarStatus,
  CarType,
  Deactivated,
  DocInsuranceCover,
  HasGPS,
  OfferGPS,
  ConfirmedGPS,
  IsEcofriendly,
  StatusInsuranceCover,
  StatusVehicleLicense,
  Transmission,
  ValidateInfo,
} from "./types";

export const mockCar: Car = {
  id: 2,
  userId: 34,
  brand: "ACURA",
  model: "MDX",
  version: "SH 5P V6 3.5L AWD ABS BA NAVI R20 AUT 7 OCUP",
  year: "2018",
  amis: "03969",
  plate: "F90129",
  niv: "",
  transmission: Transmission.MANUAL,
  type: CarType.SEDAN,
  numSeats: 1,
  docVehicleLicense: ValidateInfo.NOT_UPLOADED,
  price: "340",
  questionnaireAnswers: "111",
  characteristicsList: "0111000000000000000000",
  status: CarStatus.ACTIVE,
  description: "ACURA en buenas condiciones",
  rules:
    "- No esta permitido subir mascotas 🐕 - No esta permitido fumar 🚬- No comer dentro del auto 🍕- Dejar el auto limpio ✨- Regresar el tanque con el mismo nivel de gasolina ⛽️",
  commission: "30.00",
  isReadyByHost: Deactivated.READY,
  numBags: 3,
  vehicleLicenseExpirationDate: "0000-00-00 00:00:00",
  statusVehicleLicense: StatusVehicleLicense.ABOUT_TO_EXPIRE,
  docInsuranceCover: DocInsuranceCover.PENDING,
  insuranceCoverExpirationDate: "0000-00-00 00:00:00",
  statusInsuranceCover: StatusInsuranceCover.ABOUT_TO_EXPIRE,
  hasGPS: HasGPS.FALSE,
  offerGPS: OfferGPS.INTERESTED,
  confirmedGPS: ConfirmedGPS.FALSE,
  experiences: "000000000",
  isEcoFriendly: IsEcofriendly.HYBRID,
  isCarComplete: true,
  destinationId: 0,
};

export const mockCarSecond: Car = {
  id: 2,
  userId: 3,
  brand: "HYUNDAI",
  model: "SONATA",
  version: "LIMITED 4P L4 2.4L TSOLAR AUT 5 OCUP",
  year: "2015",
  amis: "05368",
  plate: "JLS778G",
  niv: "5NPE34AF6FH075512",
  transmission: 0,
  type: 0,
  numSeats: 4,
  price: "1300",
  questionnaireAnswers: "",
  characteristicsList: "0011011100111001011010",
  status: 2,
  description: "descripcion",
  rules: "reglas",
  commission: "20.00",
  isReadyByHost: 0,
  docVehicleLicense: 2,
  vehicleLicenseExpirationDate: "10-12-2024",
  statusVehicleLicense: 0,
  docInsuranceCover: 2,
  insuranceCoverExpirationDate: "10-12-2024",
  statusInsuranceCover: 0,
  isEcoFriendly: 0,
  experiences: "000001110",
  numBags: 3,
  hasGPS: HasGPS.FALSE,
  offerGPS: OfferGPS.INTERESTED,
  confirmedGPS: ConfirmedGPS.FALSE,
  step: 4,
  destinationId: 0,
};

export const mockUnCompleteCar: Car = {
  id: 10,
  userId: 3,
  brand: "ACURA",
  model: "ILX",
  version: "PREMIUM 4P L4 2.0L SPORTSHIFT AUT., 05 OCUP.",
  year: "2013",
  amis: "08514",
  plate: "",
  niv: "",
  transmission: 0,
  type: 0,
  numSeats: 0,
  price: "0",
  questionnaireAnswers: "",
  characteristicsList: "0000000000000000000000",
  status: 0,
  description: "",
  rules: "",
  commission: "20.00",
  isReadyByHost: 0,
  docVehicleLicense: 0,
  vehicleLicenseExpirationDate: "01-01-0001",
  statusVehicleLicense: 0,
  docInsuranceCover: 0,
  insuranceCoverExpirationDate: "01-01-0001",
  statusInsuranceCover: 0,
  isEcoFriendly: 0,
  experiences: "000000000",
  numBags: 0,
  hasGPS: HasGPS.FALSE,
  offerGPS: OfferGPS.INTERESTED,
  confirmedGPS: ConfirmedGPS.FALSE,
  step: 1,
  destinationId: 0,
};

export const MockCarSpecs: carSpecifications = {
  carBrand: "",
  carType: "",
  carYear: "",
  carVersion: "",
};

export const mockCarTestSpecs: carSpecifications = {
  carBrand: "ACURA",
  carType: "MDX",
  carYear: "2018",
  carVersion: "SH 5P V6 3.5L AWD ABS BA NAVI R20 AUT 7 OCUP",
};

export const mockUpdateCarTest: UpdateCarDTO = {
  amis: "03969",
  plate: "F90129",
  niv: "",
  transmission: Transmission.MANUAL,
  type: CarType.SEDAN,
  numSeats: 1,
  docVehicleLicense: ValidateInfo.NOT_UPLOADED,
  price: "340",
  questionnaireAnswers: "111",
  characteristicsList: "0111000000000000000000",
  status: CarStatus.ACTIVE,
  description: "ACURA en buenas condiciones",
  rules:
    "- No esta permitido subir mascotas 🐕 - No esta permitido fumar 🚬- No comer dentro del auto 🍕- Dejar el auto limpio ✨- Regresar el tanque con el mismo nivel de gasolina ⛽️",
  commission: "30.00",
  isReadyByHost: Deactivated.READY,
  numBags: 3,
  vehicleLicenseExpirationDate: "2023-02-22 09:12:15",
  statusVehicleLicense: StatusVehicleLicense.ABOUT_TO_EXPIRE,
  docInsuranceCover: DocInsuranceCover.PENDING,
  insuranceCoverExpirationDate: "2023-02-22 09:12:15",
  statusInsuranceCover: StatusInsuranceCover.ABOUT_TO_EXPIRE,
  hasGPS: HasGPS.FALSE,
  offerGPS: OfferGPS.INTERESTED,
  confirmedGPS: ConfirmedGPS.TRUE_JEST,
  experiences: "000000000",
  isEcoFriendly: IsEcofriendly.HYBRID,
  isCarComplete: false,
};

export const mockCars = [
  {
    id: 1,
    userId: 2,
    brand: "ACURA",
    model: "ILX",
    version: "LUXURY 4P L4 2.0L ABS BA QC AC R16 CAM TRAS AUT 5 OCUP",
    year: "2014",
    amis: "03966",
    plate: "JLS7780",
    niv: "5NPE34AF6FH075511",
    transmission: 0,
    type: 0,
    numSeats: 5,
    price: "1200",
    questionnaireAnswers: "",
    characteristicsList: "0011011100111001011010",
    status: 2,
    description: "descripcion",
    rules: "reglas",
    commission: "30.00",
    isReadyByHost: 0,
    docVehicleLicense: 2,
    vehicleLicenseExpirationDate: "10-12-2024",
    statusVehicleLicense: 0,
    docInsuranceCover: 2,
    insuranceCoverExpirationDate: "10-12-2024",
    statusInsuranceCover: 0,
    isEcoFriendly: 0,
    experiences: "000001111",
    numBags: 12,
    datetimeCreated: "2023-07-10T20:31:14",
    datetimeCompleted: "2023-07-10T20:31:14",
    datetimeActive: "0001-01-01T00:00:00",
    hasGPS: HasGPS.FALSE,
    offerGPS: OfferGPS.INTERESTED,
    confirmedGPS: ConfirmedGPS.FALSE,
    destinationId: 1,
    step: 4,
    address:
      "Domingo Sarmiento 3075, Prados Providencia, 44670 Guadalajara, Jal., Mexico",
    latitude: "20.6854362",
    longitude: "-103.3927796",
    typeResponse: 3,
  },
  {
    id: 2,
    userId: 3,
    brand: "HYUNDAI",
    model: "SONATA",
    version: "LIMITED 4P L4 2.4L TSOLAR AUT 5 OCUP",
    year: "2015",
    amis: "05368",
    plate: "JLS778G",
    niv: "5NPE34AF6FH075512",
    transmission: 0,
    type: 0,
    numSeats: 4,
    price: "1300",
    questionnaireAnswers: "",
    characteristicsList: "0011011100111001011010",
    status: 2,
    description: "descripcion",
    rules: "reglas",
    commission: "20.00",
    isReadyByHost: 0,
    docVehicleLicense: 2,
    vehicleLicenseExpirationDate: "10-12-2024",
    statusVehicleLicense: 0,
    docInsuranceCover: 2,
    insuranceCoverExpirationDate: "10-12-2024",
    statusInsuranceCover: 0,
    isEcoFriendly: 0,
    experiences: "000001110",
    numBags: 3,
    datetimeCreated: "2023-07-10T20:31:14",
    datetimeCompleted: "2023-07-10T20:31:14",
    datetimeActive: "0001-01-01T00:00:00",
    hasGPS: HasGPS.FALSE,
    offerGPS: OfferGPS.INTERESTED,
    confirmedGPS: ConfirmedGPS.FALSE,
    destinationId: 1,
    step: 4,
    address:
      "Domingo Sarmiento 3075, Prados Providencia, 44670 Guadalajara, Jal., Mexico",
    latitude: "20.6854362",
    longitude: "-103.3927796",
    typeResponse: 3,
  },
];
